import { getBbxCookiesFromRequest } from '@wh/common/chapter/types/cookies'
import { Request } from 'express'
import {
    CdcFeedRequest,
    CdcLastViewedAds,
    LastViewedAdsSliderType,
    NearbyWidgetType,
    RecommendationWidgetType,
    SearchHistory,
    UserFeed,
} from '@bbx/common/types/UserFeed'
import { ContextLink } from '@bbx/common/types/contextLinks'
import { wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import { VerticalId } from '@wh/common/chapter/types/verticals'
import { NextRequest } from '@wh/common/chapter/types/nextJS'
import { fetcher, getCsrfHeader } from '@wh/common/chapter/api/fetcher'
import { urlSearchParamsFromObject } from '@wh/common/chapter/api/urlSearchParams'
import { ParsedUrlQuery } from 'querystring'

// one retry for 401s, then return a hardcoded feed
export const feedErrorHandler = async (getFeed: () => Promise<UserFeed>, errorFeed: UserFeed): Promise<UserFeed> => {
    let feed

    try {
        feed = await getFeed()
    } catch (error) {
        const apiError = wrapInApiErrorIfNecessary(error)
        console.error('getFeed() did return ', apiError?.statusCode)
        feed = errorFeed

        if (apiError.statusCode && apiError.statusCode === 401) {
            try {
                feed = await getFeed()
            } catch (secondError) {}
        }
    }

    return feed
}

export const getUserFeed = (
    uuid: string,
    feedRequest?: CdcFeedRequest,
    request?: NextRequest,
    query?: ParsedUrlQuery,
): Promise<UserFeed> => {
    const srUserProfileParam = query?.srUserProfile ? `&srUserProfile=${query.srUserProfile}` : ''

    return fetcher<UserFeed>(`/cdc/userfeed/${uuid}?locationServicesEnabled=true${srUserProfileParam}`, {
        method: 'POST',
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
        headers: getCsrfHeader(request),
        body: JSON.stringify(feedRequest),
    })
}

export const getAnonymousUserFeed = (iadVisitorCookie: string, feedRequest?: CdcFeedRequest, request?: NextRequest): Promise<UserFeed> => {
    return fetcher<UserFeed>(`/cdc/userfeed/${iadVisitorCookie}/anonymous?locationServicesEnabled=true`, {
        method: 'POST',
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
        headers: getCsrfHeader(request),
        body: JSON.stringify(feedRequest),
    })
}

export const getNearbyWidget = (lat: string, lon: string, abortSignal?: AbortSignal, request?: Request): Promise<NearbyWidgetType> => {
    return fetcher<NearbyWidgetType>(`/cdc/userfeed/nearby-widget?${new URLSearchParams({ lat, lon })}`, {
        signal: abortSignal,
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })
}

export const getSearchHistory = (uuid: string, abortSignal?: AbortSignal, request?: Request): Promise<SearchHistory> => {
    return fetcher<SearchHistory>(`/cdc/searchhistory/${uuid}`, {
        signal: abortSignal,
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })
}

export const deleteSearchHistoryEntryFromLink = (
    contextLink: ContextLink,
    abortSignal?: AbortSignal,
    request?: Request,
): Promise<SearchHistory> => {
    return fetcher<SearchHistory>(`/${contextLink.serviceName}${contextLink.relativePath}`, {
        method: 'DELETE',
        signal: abortSignal,
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })
}

export const getRecommendations = (uuid: string, abortSignal?: AbortSignal, request?: Request): Promise<RecommendationWidgetType> => {
    return fetcher<RecommendationWidgetType>(`/cdc/recommendation/${uuid}`, {
        signal: abortSignal,
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })
}

export const getLastViewedAds = (
    lastViewedAds: CdcLastViewedAds,
    verticalId: VerticalId,
    currentAdId?: number,
    abortSignal?: AbortSignal,
    request?: Request,
): Promise<LastViewedAdsSliderType> => {
    return fetcher<LastViewedAdsSliderType>(
        `/cdc/lastviewedads?${urlSearchParamsFromObject({ verticalId: verticalId, currentAdId: currentAdId })}`,
        {
            method: 'POST',
            signal: abortSignal,
            credentials: 'include',
            body: JSON.stringify(lastViewedAds),
            cookies: getBbxCookiesFromRequest(request),
        },
    )
}
